.Main {
  width: 100vw;
  height: 100vh;
}

Canvas {
  width: 100%;
  height: 100%;
}

.content {
  width: 720px;
  height: 480px;
  background: black;
  overflow-y: auto;
  padding: 0;
  border: solid 1px white;
  overflow-wrap: normal;
}

.website {
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 30px;
  font-family: 'JetBrains Mono', monospace;
}

/* Image */
img {
  margin-top: 20px;
  border-radius: 100%;
}

/* Content */
p {
  margin: 0;
}

h2 {
  margin-bottom: 0;
}

i {
  font-size: 15px;
}

/* Lists */
ul {
  margin: 0;
}

li {
  list-style-type: '✨';
  padding-left: 30px;
}

/* Links */
a {
  color: #a884e0;
  text-decoration: none;
}

a:hover {
  color: #5b477a;
  font-weight: bold;
}

/* Bottom of the website */
.wip {
  margin-top: 50px;
  font-size: 20px;
}

.todo {
  font-size: 20px;
  color: lightgreen;
}

footer {
  margin: 0;
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
