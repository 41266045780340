/* Scrollbar stuff */
::-webkit-scrollbar {
  width: 20px;
}
    
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
    
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 100px;
}
    
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}
  